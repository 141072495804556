const getStatus = async (paymentId: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/status?paymentId=${paymentId}`)

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`
    throw new Error(message)
  }

  const { alive } = await response.json()

  return alive
}

const getPayment = async (paymentId: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/payment?paymentId=${paymentId}`)

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`
    throw new Error(message)
  }

  const payment = await response.json()

  return payment
}

const createMolliePayment = async (paymentId: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/payment/gateway?paymentId=${paymentId}`)

  if (!response.ok) {
    const body = await response.json()
    throw new Error(body.error)
  }

  const data = await response.json()

  return data
}

const getSeatsInformation = async (paymentId: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/seats-check?paymentId=${paymentId}`)
  if (!response.ok) {
    const body = await response.json()
    throw new Error(body.error)
  }

  const data = await response.json()

  return data
}

export { createMolliePayment, getPayment, getStatus, getSeatsInformation }