import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { createMolliePayment, getStatus, getSeatsInformation } from '../api-client'
import Error from '../components/Error'
import Warning from '../components/Warning'
import Spinner from '../components/Spinner'
import logo from '../images/logo.webp'

enum Status {
  INIT = 'INIT',
  PAYMENT_ID_MISSING = 'PAYMENT_ID_MISSING',
  FETCHING_SEAT_INFORMATION = 'FETCHING_SEAT_INFORMATION',
  FETCHING_SEAT_INFORMATION_FAILED = 'FETCHING_SEAT_INFORMATION_FAILED',
  NEEDS_CONFIRMATION = 'NEEDS_CONFIRMATION',
  FETCHING_DB_STATUS = 'FETCHING_DB_STATUS',
  FETCHING_DB_STATUS_FAILED = 'FETCHING_DB_STATUS_FAILED',
  FETCHING_REDIRECT_URL = 'FETCHING_REDIRECT_URL',
  FETCHING_REDIRECT_URL_FAILED = 'FETCHING_REDIRECT_URL_FAILED',
  PAYMENT_ALREADY_PROCESSED = 'PAYMENT_ALREADY_PROCESSED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
}

const VerifyPaymentPage = () => {
  const timeoutIDRef = useRef<NodeJS.Timeout | null>(null)

  const [searchParams] = useSearchParams()
  const [paymentId, setPaymentId] = useState<string | null>(null)
  const [status, setStatus] = useState<Status>(Status.INIT)
  const [dbStatusRetries, setDbStatusRetries] = useState<number>(0)
  const [failureReturnUrl, setFailureReturnUrl] = useState<string | null>(null)

  const setTimeoutID = (timeoutID: NodeJS.Timeout | null) => {
    timeoutIDRef.current = timeoutID
  }

  const getRedirectUrl = async () => {
    try {
      const { redirectUrl } = await createMolliePayment(paymentId!)
      // @ts-ignore
      window.top.location.href = redirectUrl
    } catch (error: any) {
      if (error.message === 'PAYMENT_ALREADY_PROCESSED') {
        setStatus(Status.PAYMENT_ALREADY_PROCESSED)
      } else if (error.message === 'INTERNAL_SERVER_ERROR') {
        setStatus(Status.INTERNAL_SERVER_ERROR)
      } else {
        const timeoutID = setTimeout(() => {
          setStatus(Status.FETCHING_REDIRECT_URL_FAILED)
        }, 10000)
        setTimeoutID(timeoutID)
      }
      console.log(error.message)
    }
  }

  const getDbStatus = async () => {
    try {
      const alive = await getStatus(paymentId!)
      if (alive) {
        setStatus(Status.FETCHING_REDIRECT_URL)
        await getRedirectUrl()
      }
    } catch (error: any) {
      setDbStatusRetries(dbStatusRetries + 1)
    }
  }

  const getSeatingInformation = async () => {
    try {
      const { noSeating, adjacent, failureReturnUrl } = await getSeatsInformation(paymentId!)
      if (noSeating || (!noSeating && adjacent)) {
        setStatus(Status.FETCHING_DB_STATUS)
        return getDbStatus()
      }
      if (!adjacent) {
        // Ask for user permission to continue
        setFailureReturnUrl(failureReturnUrl)
        return setStatus(Status.NEEDS_CONFIRMATION)
      }
    } catch (error) {
      setStatus(Status.FETCHING_SEAT_INFORMATION_FAILED)
    }
  }

  const onCancel = () => {
    // @ts-ignore
    window.top.location.href = failureReturnUrl
  }

  const onConfirm = () => {
    getDbStatus()
  }

  const onRetry = () => {
    switch (status) {
      case Status.FETCHING_SEAT_INFORMATION_FAILED:
        setStatus(Status.FETCHING_SEAT_INFORMATION)
        return getSeatingInformation()
      case Status.FETCHING_DB_STATUS_FAILED:
        setStatus(Status.FETCHING_DB_STATUS)
        return getDbStatus()
      case Status.FETCHING_REDIRECT_URL_FAILED:
        setStatus(Status.FETCHING_REDIRECT_URL)
        return getRedirectUrl()
    }
  }

  const getErrorMessage = () => {
    switch (status) {
      case Status.PAYMENT_ID_MISSING:
        return 'A payment id is required to be able to verify the payment request.'
      case Status.FETCHING_SEAT_INFORMATION_FAILED:
      case Status.FETCHING_DB_STATUS_FAILED:
      case Status.FETCHING_REDIRECT_URL_FAILED:
        return `Something went wrong... The payment with id ${paymentId} couldn't be verified. Please try again.`
      case Status.PAYMENT_ALREADY_PROCESSED:
        return `Payment with id ${paymentId} is already processed. Please contact support@ticket.monster.`
      case Status.INTERNAL_SERVER_ERROR:
        return `Something went wrong... The payment with id ${paymentId} couldn't be verified. Please contact support@ticket.monster.`
      default:
        return `Something went wrong... The payment with id ${paymentId} couldn't be verified. Please try again.`
    }
  }

  const getWarningMessage = () => [
    `Jullie zitplaatsen bevinden zich niet naast elkaar omdat de categorie die jullie hebben gekozen bijna is uitverkocht. 
    Als jullie willen, kunnen jullie een andere categorie proberen of gewoon doorgaan zoals gepland.`,
    `Your seats are not next to each other because the category you chose is almost sold out. 
    If you want, you can try another category or just go ahead as planned.`
  ]

  useEffect(() => {
    if (dbStatusRetries > 0 && dbStatusRetries < 5) {
      getDbStatus()
    } else if (dbStatusRetries !== 0) {
      setDbStatusRetries(0)
      setStatus(Status.FETCHING_DB_STATUS_FAILED)
    }
  }, [dbStatusRetries])


  // Step 2: The payment id is stored in the state, request seating information.
  // The result of this will indicate whether or not all seats are neighbours.
  useEffect(() => {
    if (paymentId != null) {
      setStatus(Status.FETCHING_SEAT_INFORMATION)
      getSeatingInformation()
      // Workaround
      // setStatus(Status.FETCHING_DB_STATUS)
      // getDbStatus()
    }
  }, [paymentId])

  // Step 1: Read the payment id from the url and store it in the state.
  useEffect(() => {
    const pId = searchParams.get('paymentId')
    if (pId !== paymentId) {
      setPaymentId(pId)
    } else if (pId == null) {
      setStatus(Status.PAYMENT_ID_MISSING)
    }
  }, [])

  const showSpinner = status === Status.INIT || status === Status.FETCHING_SEAT_INFORMATION || status === Status.FETCHING_DB_STATUS || status === Status.FETCHING_REDIRECT_URL
  const showError = status === Status.FETCHING_SEAT_INFORMATION_FAILED || status === Status.FETCHING_DB_STATUS_FAILED || status === Status.FETCHING_REDIRECT_URL_FAILED || status === Status.PAYMENT_ALREADY_PROCESSED || status === Status.INTERNAL_SERVER_ERROR
  const showRetry = status === Status.FETCHING_SEAT_INFORMATION_FAILED || status === Status.FETCHING_DB_STATUS_FAILED || status === Status.FETCHING_REDIRECT_URL_FAILED

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-screen flex flex-col justify-center">
      <div className=" px-4 sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={logo}
          alt=""
        />
      </div>
      <div className="max-w-lg mx-auto text-center">
        <h1 className="text-3xl font-medium text-gray-900">Verifying your payment request</h1>
        <p className="mt-2.5 text-xl text-red-600">Please do not refresh this page.</p>
        {status !== Status.NEEDS_CONFIRMATION && <p className="mt-2.5 text-xl text-gray-500">Once we've verified your payment request you'll be redirected to Mollie.</p>}
        {status === Status.NEEDS_CONFIRMATION && <Warning className="mt-2.5 text-left" messages={getWarningMessage()} />}
        {showError && <Error className="mt-2.5 text-left" message={getErrorMessage()} />}
        {showSpinner && (<Spinner className="mt-2.5" />)}
        {showRetry && (
          <button
            type="button"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            onClick={onRetry}
          >
            Try again
          </button>
        )}
        {status === Status.NEEDS_CONFIRMATION && (
          <>
            <button
              type="button"
              className="mt-4 mr-8 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={onConfirm}
            >
              Ga verder / Proceed
            </button>
            <button
              type="button"
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={onCancel}
            >
              Ga terug / Go back
            </button>
          </>
        )}
      </div> 
    </div>
  )
}

export default VerifyPaymentPage