import React from 'react'
import { ExclamationIcon } from '@heroicons/react/solid'

type Props = {
  children?: React.ReactNode
  className?: string
  messages: string[]
}

const Warning: React.FC<Props> = ({ className='', children, messages }) => {
  
  return (
    <div className={`${className} rounded-md bg-orange-50 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-orange-800">{messages[0]}</h3>
          <hr style={{ marginTop: '10px', marginBottom: '10px' }} className="border-orange-800"></hr>
          <h3 className="text-sm font-medium text-orange-800">{messages[1]}</h3>
          {children && (
            <div className="mt-2 text-sm text-orange-700">
              {children}
            </div>)}
        </div>
      </div>
    </div>
  )
}

export default Warning
